import { createRouter } from '@/plugins/vue-router';
import JwtService from '@/common/jwt.service';

export const bootstrap = (app, modules) => {
  let routes = [];
  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    if (module.routes) {
      routes.push(...module.routes);
    }

    if (module.extend) {
      module.extend(app);
    }
  }
  routes = routes.map((route) => {
    if (route.path === '/') {
      route.redirect = '/global-search';
    }
    return route;
  });
  const router = createRouter(routes);

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (JwtService.getToken('auth')) {
        next();
      } else {
        next('/auth/login');
      }
    } else {
      next();
    }
  });

  router.onError(error => {
    const errorMsg = ['Failed to fetch dynamically imported module', 'was blocked because of a disallowed MIME type', 'TypeError: error loading dynamically imported module', 'Loading module from', 'Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of'];
    errorMsg.forEach((msg) => {
      if(error.message.includes(msg)) {
        const reloadByChunk = localStorage.getItem('reloadByChunk');
        if(!reloadByChunk) {
          localStorage.setItem('reloadByChunk', 'RELOAD');
          window.location.reload();
        }
      }
    })
  })

  if(localStorage.getItem('reloadByChunk')) {
    console.log('Произошла перезагрузка страницы');
    setTimeout(() => {
      console.info('Очистили флаг перезагрузки через 60 секунд после перезагрузки');
      localStorage.removeItem('reloadByChunk');
    }, 60000)
  }

  app.use(router);

  return app;
};
